import React from "react"
//import { graphql } from "gatsby"
import Layout from "../components/layout-minhelt/layout"

//import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
//import useViewportWidth from "../hooks/useViewportWidth"
import ContactForm0 from "../components/ContactForm/contact-form-0"
import { string } from '../util/strings'
import { useSelector, useDispatch } from "react-redux"
import Hero from "../components/hero"
import FramePlaceholder from "../components/frame-placeholder"
import Hs from "../images/h-sperator.svg"

const IndexPage = ({ data, ...props }) => {


  const location = props.location
  const { search } = location

  const success = search === '?success'

  const seo = {}

  //const width = useViewportWidth()
  //const isMobile = width <= 768
  //console.log('width, isMobile = ', width, isMobile)


  return (
    <Layout>
      <Seo {...seo} />
      <Hero
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        title={'צור קשר'}
        subtitle={''}
        url={'/backgrounds/contact-us.svg'}
      />


      <section className="lg:py-12 ">
        {success && <Success />}

        {!success && <FramePlaceholder flip >
          <div className="space-y-8 py-12 px-4 lg:px-24 border shadow-lg">
            <h2 className="mb-2 text-blue text-[34px]  lg:text-center"><img src={Hs} alt="" className="inline ml-1.5" />{string('contact.title')}</h2>
            <ContactBlock />
          </div>
        </FramePlaceholder>
        }
      </section>

      <section className="pt-16 pb-12">
        <div className="container flex flex-row justify-center">
          <div className="lg:px-24 lg:text-2xl  ">
            <div>טלפון: 086315353 שלוחה 7</div>
            <div>דואר אלקטרוני: met@eec.co.il</div>
          </div>
        </div>
      </section>

    </Layout >
  )
}

export default IndexPage

const ContactBlock = () => {

  const id = 'minhelet'

  const { forms } = useSelector(state => state.contact)
  const { message, loading } = forms.find(e => e.id === id)
  const dispatch = useDispatch()

  const sendContact = (values) => {

    /* const data = {
      "contact_form": [{ "target_id": "contactUs" }],
      "name": [{ "value": values.name }],
      "mail": [{ "value": values.email }],
      "subject": [{ "value": values.subject }],
      "message": [{ "value": values.text }]
    }
 */

    const data = {
      "field_name": [values.name],
      "field_phone": [values.phone],
      "field_email": [values.email],
      "contact_form": "feedback",
      "subject": [values.subject],
      "field_subject": [values.subject],
      "message": [values.text]
    }

    dispatch({ type: 'contact/send', payload: { id: id, data: data } })

    //console.log('data', data)

  }

  return (
    <div className="w-full ">
      <ContactForm0 onSubmit={sendContact} loading={loading} message={message} />
    </div>
  )
}

export const Head = () => <title>צור קשר</title>


const Success = () => {

  return (

    <div className="container my-8 text-center">
      <h2 className="text-2xl">תודה שכתבת לנו</h2>
      <div>אחד מהנציגים שלנו יחזור אלייך בהקדם</div>
    </div>
  )


}
