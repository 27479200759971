import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { string } from '../../util/strings'

const validate = (values) => {
  const errors = {};

  const required = string('form.require')

  //console.log(values)

  if (!values.subject) {
    errors.subject = required;
  }

  if (!values.name) {
    errors.name = required;
  }

  if (!values.text) {
    errors.text = required;
  }

  if (!values.phone) {
    errors.phone = required;
  }

  if (!values.email) {
    errors.email = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = string('form.Invalid email address')
  }

  return errors;
};

const ContactForm0 = (props) => {
  const { onSubmit, loading/* , message */ } = props

  const subjects = [
    '',
    ` אני יזם/ית`,
    `אני בעל/ת קרקע באזורי התעסוקה`,
    `אני בעל/ת עסק באזורי התעסוקה`,
    `אשמח לשאלה`,
    `אחר:`,
  ]
  return (
    <div className="container ">

      <Formik
        initialValues={{
          subject: "",
          name: "",
          phone: "",
          email: "",
          text: "",
        }}
        validate={validate}
        onSubmit={async (values) => {
          onSubmit(values)
        }}
      >
        <Form className="">


          <div className=" flex flex-col justify-center space-y-3 ">

            <div className="w-full">



              <Field
                as="select"
                name="subject"
                className="form-control px-4 py-3 border placeholder:text-grey2 border-grey2 w-full"
              >
                {subjects.map((e, index) => (
                  <option value={e} key={index}>{e}</option>
                ))}

              </Field>


              <ErrorMessage name="subject" component="div" className="error" />
            </div>
            <div className="w-full">

              <Field
                id="name"
                name="name"
                placeholder={string('contact.name')}
                className="form-control px-4 py-3 border placeholder:text-grey2 border-grey2 w-full"
              />
              <ErrorMessage name="name" component="div" className="error" />
            </div>

            <div className="w-full">
              <Field
                id="phone"
                name="phone"
                step="1"
                min="1"
                max="5"
                className="form-control  px-4 py-3 border placeholder:text-grey2  border-grey2 w-full"
                placeholder={string('contact.phone')}
              />
              <ErrorMessage name="phone" component="div" className="error" />
            </div>

            <div className="w-full">

              <Field
                id="email"
                name="email"
                placeholder={string('contact.mail')}
                type="email"
                className="form-control  px-4 py-3 border placeholder:text-grey2  border-grey2 w-full"
                style={{ direction: "RTL" }}
              />
              <ErrorMessage name="email" component="div" className="error" />
            </div>


            <div className="w-full">

              <Field
                as={"textarea"}
                id="text"
                name="text"
                placeholder={string('contact.body')}
                className="form-control border px-4 py-3 placeholder:text-grey2 border-grey2 w-full"
                rows="7"
              />
              <ErrorMessage name="text" component="div" className="error" />
            </div>



          </div>

          <div className="text-center">
            <button type="submit" className="text-center border bg-navy text-white px-4 py-3 w-full">
              {string('contact.send')} {" "}
              {loading &&
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">{string('form.loading...')}</span>
                </>
              }
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default ContactForm0;
